import Gallery from "../components/Gallery";

function Ressources() {
  return (
    <div className="">
      <div className="container max-w-[90%] mx-auto flex items-center justify-center">
        <Gallery title={"Accélérez votre stratégie marketing vidéo."} />
      </div>
    </div>
  );
}

export default Ressources;
