import Gallery from "../components/Gallery";

function Collection() {
  return (
    <div className="">
      <div className="container max-w-[90%] mx-auto flex items-center justify-center">
        <Gallery
          title={"Les best practices pour construire votre stratégie vidéo"}
        />
      </div>
    </div>
  );
}

export default Collection;
