import React from "react";
import Gallery from "../components/Gallery";

function Creators() {
  return (
    <div className="">
      <div className="container max-w-[90%] mx-auto flex items-center justify-center">
        <Gallery title={"Les meilleurs talents pour tous vos projets vidéos"} />
      </div>
    </div>
  );
}

export default Creators;
