import React, { useState } from "react";
import HeroPages from "../components/HeroPages";
import axios from "axios";

function ContactPage() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    Message: "",
    Prenom: "",
    Nom: "",
    Email: "",
    Fonction: "",
    Society: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccess("");
    setError("");

    const emailData = {
      sender: {
        name: "Video Colibri Client",
        email: "osamaalpha1997@gmail.com",
      },
      to: [{ email: "khalil@colibristudio.co" }],
      subject: "New Contact Form Submission",
      htmlContent: `
        <h2>New Message from ${formData.Prenom} ${formData.Nom}</h2>
        <p><strong>Email:</strong> ${formData.Email}</p>
        <p><strong>Fonction:</strong> ${formData.Fonction}</p>
        <p><strong>Society:</strong> ${formData.Society}</p>
        <p><strong>Message:</strong> ${formData.Message}</p>
      `,
    };

    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/smtp/email",
        emailData,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key":
              "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",
          },
        }
      );

      if (response.status === 201) {
        setSuccess("Email sent successfully!");
        setFormData({
          Message: "",
          Prenom: "",
          Nom: "",
          Email: "",
          Fonction: "",
          Society: "",
        });
      } else {
        throw new Error("Failed to send email");
      }
    } catch (err) {
      setError("There was an error sending the email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="flex flex-col items-center justify-center">
        <HeroPages
          title={"Colibri vous accompagne sur tous vos projets vidéo"}
          desc={""}
          image={"./high-angle-cute-arrangement-table.jpg"}
        />
        <div className="container max-w-[90%] overflow-hidden mx-auto flex flex-col items-center py-[50px]">
          <div className="w-full max-w-[500px] h-auto flex flex-col p-[20px] rounded-[16px] gap-y-[30px]">
            <div className="w-full">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-y-[10px]"
              >
                <div className="form-group">
                  <label className="text-[12px] font-[600]">Message</label>
                  <textarea
                    name="Message"
                    value={formData.Message}
                    onChange={handleChange}
                    placeholder="Message"
                    required
                    className="w-full p-2 bg-black/5 border-none text-[14px] rounded-md"
                  />
                </div>

                <div className="form-group">
                  <label className="text-[12px] font-[600]">Prénom</label>
                  <input
                    name="Prenom"
                    value={formData.Prenom}
                    onChange={handleChange}
                    placeholder="Prénom"
                    className="w-full p-2 bg-black/5 border-none text-[14px] rounded-md"
                  />
                </div>

                <div className="form-group">
                  <label className="text-[12px] font-[600]">Nom</label>
                  <input
                    name="Nom"
                    value={formData.Nom}
                    onChange={handleChange}
                    placeholder="Nom"
                    className="w-full p-2 bg-black/5 border-none text-[14px] rounded-md"
                  />
                </div>

                <div className="form-group">
                  <label className="text-[12px] font-[600]">Email</label>
                  <input
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="w-full p-2 bg-black/5 border-none text-[14px] rounded-md"
                  />
                </div>

                <div className="form-group">
                  <label className="text-[12px] font-[600]">Fonction</label>
                  <input
                    name="Fonction"
                    value={formData.Fonction}
                    onChange={handleChange}
                    placeholder="Fonction"
                    required
                    className="w-full p-2 bg-black/5 border-none text-[14px] rounded-md"
                  />
                </div>

                <div className="form-group">
                  <label className="text-[12px] font-[600]">Société</label>
                  <input
                    name="Society"
                    value={formData.Society}
                    onChange={handleChange}
                    placeholder="Société"
                    required
                    className="w-full p-2 bg-black/5 border-none text-[14px] rounded-md"
                  />
                </div>

                <button
                  type="submit"
                  className="bg-primary p-2 mt-[30px] rounded-md text-white w-full"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Envoyer"}
                </button>

                {success && <p className="text-green-500 mt-4">{success}</p>}
                {error && <p className="text-red-500 mt-4">{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
