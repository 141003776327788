import Creator from '../components/Creators';

const desc = {
    title: "Les tarifs d'un filmmaker",
    desc: "Nous nous occupons de trouver les meilleurs pour vous.",
}

const sub = [
  {
    type: "Basic",
    plan: 'Freelances',
    service: 'Filmmaker',
    Populaire:false,
    price: "À partir de 950 € / jour",
    desc: "Le choix le plus adapté pour des vidéos simples et professionnelles.",
    benifits : [
      "Sélection d'un freelance par Colibri",
      "Tarif négocié par Colibri",
      "Réponse sous 48h max",
      "Gestion de projet par le client",
      "Accés à la plateforme",
      "Facturation et paiement sécurisés via Colibri"
    ]
  },
  {
    type: "Standard",
    plan: 'Collectif de freelances',
    service: 'Filmmaker',
    Populaire: false,
    price: "À partir de 1 280 € / jour",
    desc: "Le meilleur rapport qualité/prix pour une production vidéo supérieure.",
    benifits : [
      "Sélection d'un freelance par Colibri",
      "Tarif négocié par Colibri",
      "Réponse sous 48h max",
      "Gestion de projet par le client",
      "Accés à la plateforme",
      "Facturation et paiement sécurisés via Colibri"
    ]
  },
  {
    type: "Premium",
    plan: 'Agence',
    service: 'Filmmaker',
    Populaire:true,
    price: "Sur demande",
    desc: "L’option idéale si vous cherchez un concept créatif unique !",
    benifits : [
      "Accompagnement à l’écriture du brief",
      "Appel d’offres réalisé par Colibri",
      "Réponse sous 3 jours max",
      "Gestion de projet par le client",
      "Accés à la plateforme",
      "Tarifs négociés par Colibri"
    ]
  }
]
const hero = {
  title: "Filmmaker",
  desc: "C’est la pièce maitresse d’un tournage. Il cadre les interviews et réalise les images de coupe. De son travail dépend la qualité de la vidéo finale.",
  image: "./man-filming-with-professional-camera-new-movie.jpg"
}

function Filmmaker() {
  return (
    <Creator desc={desc} sub={sub} hero={hero}/>
  )
}

export default Filmmaker