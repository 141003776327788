import Gallery from "../components/Gallery";

function Videos() {
  return (
    <div className="">
      <div className="container max-w-[90%] mx-auto flex items-center justify-center">
        <Gallery
          title={
            "Toutes les vidéos dont vous avez besoin pour votre croissance"
          }
        />
      </div>
    </div>
  );
}

export default Videos;
